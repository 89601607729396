// 应用路由
const routers = [
  //上传
  {
    path: "upload",
    name: "upload",
    meta: { hidden: true },
    component: () => import("@/views/upload/Upload.vue"),
  },
  //管理档期
  {
    path: "archivetime",
    name: "Archivetime",
    meta: { hidden: true },
    component: () => import("@/views/archivetime/List.vue"),
  },
  //数据源
  {
    path: "dataSource",
    name: "DataSource",
    meta: { hidden: false },
    component: () => import("@/views/dataSource/Index2.vue"),
    children: [
      {
        path: "store",
        name: "Store",
        meta: { hidden: false },
        component: () => import("@/views/dataSource/Store.vue"),
      },
      {
        path: "execution",
        name: "Execution",
        meta: { hidden: false },
        component: () => import("@/views/dataSource/Execution.vue"),
      },
      {
        path: "person",
        name: "Person",
        meta: { hidden: false },
        component: () => import("@/views/dataSource/Person.vue"),
      },
      {
        path: "day",
        name: "Day",
        meta: { hidden: false },
        component: () => import("@/views/dataSource/Day.vue"),
      },
      {
        path: "week",
        name: "Week",
        meta: { hidden: false },
        component: () => import("@/views/dataSource/Week.vue"),
      },
    ],
  },
  //集成报表
  {
    path: "integration",
    name: "Integration",
    meta: { hidden: false },
    component: () => import("@/views/integration/Index.vue"),
    children: [
      {
        path: "itemResult",
        name: "ItemResult",
        meta: { hidden: false },
        component: () => import("@/views/integration/ItemResult.vue"),
      },
      {
        path: "personResult",
        name: "PersonResult",
        meta: { hidden: false },
        component: () => import("@/views/integration/PersonResult.vue"),
      },
      {
        path: "executionResult",
        name: "ExecutionResult",
        meta: { hidden: false },
        component: () => import("@/views/integration/ExecutionResult.vue"),
      },
      {
        path: "demoTeamItem",
        name: "DemoTeamItem",
        meta: { hidden: false },
        component: () => import("@/views/integration/DemoTeamItem.vue"),
      },
      {
        path: "demoTeamResult",
        name: "DemoTeamResult",
        meta: { hidden: false },
        component: () => import("@/views/integration/DemoTeamResult.vue"),
      },
    ],
  },
  // {
  //   path: "demo",
  //   name: "demo",
  //   meta: { hidden: false },
  //   component: () => import("@/views/demo/List.vue"),
  // },
  // {
  //   path: "demo2",
  //   name: "demo2",
  //   meta: { hidden: false },
  //   component: () => import("@/views/demo2/List.vue"),
  // },
];
export default routers;
