// 默认菜单，没有从后台获取到使用的备用
const menus: any = [
  {
    icon: null,
    idx: "1",
    meta: {},
    name: "基础管理",
    key: "MenuBasic_pg_basicManage",
    children: [
      {
        disable: false,
        icon: null,
        idx: "1/1",
        meta: { hideIcon: true },
        name: "数据管理",
        openType: 1,
        path: "/archivetime",
        path0: "/archivetime",
        key: "MenuBasic_pg_basicManage_archivetime",
        children: [
          {
            disable: false,
            icon: null,
            idx: "1/1/1",
            meta: { hidden: true },
            name: "上传数据",
            openType: 1,
            path: "/upload",
            path0: "/upload",
            hidden: true,
            key: "MenuBasic_pg_basicManage_archivetime_upload",
          },
        ],
      },
    ],
  },
  {
    icon: null,
    idx: "3",
    meta: {},
    name: "基础报表",
    key: "MenuBasic_pg_dataSource",
    children: [
      {
        disable: false,
        icon: null,
        idx: "3/1",
        meta: {},
        name: "Demo 终端/商品效益",
        openType: 1,
        path: "/dataSource/store",
        path0: "/dataSource/store",
        key: "MenuBasic_pg_dataSource_store",
      },
      {
        disable: false,
        icon: null,
        idx: "3/2",
        meta: {},
        name: "Demo执行",
        openType: 1,
        path: "/dataSource/execution",
        path0: "/dataSource/execution",
        key: "MenuBasic_pg_dataSource_execution",
      },
      {
        disable: false,
        icon: null,
        idx: "3/3",
        meta: {},
        name: "人员管理",
        openType: 1,
        path: "/dataSource/person",
        path0: "/dataSource/person",
        key: "MenuBasic_pg_dataSource_person",
      },
      {
        disable: false,
        icon: null,
        idx: "3/4",
        meta: {},
        name: "天VOM",
        openType: 1,
        path: "/dataSource/day",
        path0: "/dataSource/day",
        key: "MenuBasic_pg_dataSource_day",
      },
      {
        disable: false,
        icon: null,
        idx: "3/5",
        meta: {},
        name: "周VOM",
        openType: 1,
        path: "/dataSource/week",
        path0: "/dataSource/week",
        key: "MenuBasic_pg_dataSource_week",
      },
    ],
  },
  {
    icon: null,
    idx: "2",
    meta: {},
    name: "集成报表",
    key: "MenuBasic_pg_integration",
    children: [
      {
        disable: false,
        icon: null,
        idx: "2/1",
        meta: {},
        name: "档期Demo item表现",
        openType: 1,
        path: "/integration/itemResult",
        path0: "/integration/itemResult",
        key: "MenuBasic_pg_integration_itemResult",
      },
      {
        disable: false,
        icon: null,
        idx: "2/2",
        meta: {},
        name: "促销员绩效",
        openType: 1,
        path: "/integration/personResult",
        path0: "/integration/personResult",
        key: "MenuBasic_pg_integration_personResult",
      },
      {
        disable: false,
        icon: null,
        idx: "2/3",
        meta: {},
        name: "执行情况",
        openType: 1,
        path: "/integration/executionResult",
        path0: "/integration/executionResult",
        key: "MenuBasic_pg_integration_executionResult",
      },
      {
        disable: false,
        icon: null,
        idx: "2/4",
        meta: {},
        name: "Demo team item",
        openType: 1,
        path: "/integration/demoTeamItem",
        path0: "/integration/demoTeamItem",
        key: "MenuBasic_pg_integration_demoTeamItem",
      },
      {
        disable: false,
        icon: null,
        idx: "2/5",
        meta: {},
        name: "Demo team 执行情况",
        openType: 1,
        path: "/integration/demoTeamResult",
        path0: "/integration/demoTeamResult",
        key: "MenuBasic_pg_integration_demoTeamResult",
      },
    ],
  },
];
// /api/iam/v1/a/odic/authc
export default menus;
